<template>
  <vca-card>
    <h2>{{ $t("faqs.goldeimer.title") }}</h2>
    <p v-html="$t('faqs.goldeimer.description')" />
    <br />
    <Goldeimer />
  </vca-card>
</template>
<script>
import Goldeimer from "@/components/faqs/FaqsGoldeimer";
export default {
  name: "FaqsGoldeimer",
  components: { Goldeimer },
};
</script>
